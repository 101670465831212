/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useObserver } from '@/hooks';

import { Container, Wrapper, Text, Ul, Li } from './styles';

const Statute = () => {
  const { contentJson } = useStaticQuery(
    graphql`
      query {
        contentJson(statute: { id: { eq: "statute" } }) {
          statute {
            sections {
              name
              articles {
                title
                texts
                list
              }
            }
          }
        }
      }
    `
  );
  const { setPosition } = useObserver();

  return (
    <Container>
      {contentJson.statute.sections.map(({ name, articles }, index) => (
        <Wrapper key={index} onTouchStart={setPosition} onMouseEnter={setPosition}>
          <Text>{name}</Text>
          {articles.map(({ title, texts, list }, key) => (
            <Fragment key={key}>
              {title && <Text mt>{title}</Text>}
              {texts && texts.map((text, i) => <Text key={i}>{text}</Text>)}
              {list && (
                <Ul>
                  {list.map((item, i) => (
                    <Li key={i}>{item}</Li>
                  ))}
                </Ul>
              )}
            </Fragment>
          ))}
        </Wrapper>
      ))}
    </Container>
  );
};

export default Statute;
